import { _mock } from './_mock';

// ----------------------------------------------------------------------

export const _carouselsMembers = [...Array(8)].map((_, index) => ({
  id: index,
  name: ['Орлов Алексей Андреевич', 'Иван Пертов', 'Илья Новиков', 'Найдежда Зайцева', 'Лиза Семенова', 'Иван Пертов', 'Илья Новиков', 'Найдежда Зайцева'][index],
  role: ['Инженер-конструктор', 'Инженер', 'Директор', 'Дизайнер', 'Менеджер', 'Инженер', 'Директор', 'Дизайнер'][index],
  avatarUrl: [
    '/assets/images/portrait/1.png',
    '/assets/images/portrait/2.png',
    '/assets/images/portrait/3.png',
    '/assets/images/portrait/4.png',
    '/assets/images/portrait/5.png',
    '/assets/images/portrait/6.png',
    '/assets/images/portrait/7.png',
    '/assets/images/portrait/8.png',
  ][index],
}));

// ----------------------------------------------------------------------

export const _faqs = [...Array(8)].map((_, index) => ({
  id: _mock.id(index),
  value: `panel${index + 1}`,
  heading: `Questions ${index + 1}`,
  detail: _mock.description(index),
}));

// ----------------------------------------------------------------------

export const _addressBooks = [...Array(24)].map((_, index) => ({
  id: _mock.id(index),
  primary: index === 0,
  name: _mock.fullName(index),
  email: _mock.email(index + 1),
  fullAddress: _mock.fullAddress(index),
  phoneNumber: _mock.phoneNumber(index),
  company: _mock.companyName(index + 1),
  addressType: index === 0 ? 'Home' : 'Office',
}));

// ----------------------------------------------------------------------

export const _contacts = [...Array(20)].map((_, index) => {
  const status =
    (index % 2 && 'online') || (index % 3 && 'offline') || (index % 4 && 'alway') || 'busy';

  return {
    id: _mock.id(index),
    status,
    role: _mock.role(index),
    email: _mock.email(index),
    name: _mock.fullName(index),
    phoneNumber: _mock.phoneNumber(index),
    lastActivity: _mock.time(index),
    avatarUrl: _mock.image.avatar(index),
    address: _mock.fullAddress(index),
  };
});

// ----------------------------------------------------------------------

export const _notifications = [...Array(9)].map((_, index) => ({
  id: _mock.id(index),
  avatarUrl: [
    _mock.image.avatar(1),
    _mock.image.avatar(2),
    _mock.image.avatar(3),
    _mock.image.avatar(4),
    _mock.image.avatar(5),
    null,
    null,
    null,
    null,
    null,
  ][index],
  type: ['friend', 'project', 'file', 'tags', 'payment', 'order', 'chat', 'mail', 'delivery'][
    index
  ],
  category: [
    'Communication',
    'Project UI',
    'File Manager',
    'File Manager',
    'File Manager',
    'Order',
    'Order',
    'Communication',
    'Communication',
  ][index],
  isUnRead: _mock.boolean(index),
  createdAt: _mock.time(index),
  title:
    (index === 0 && `<p><strong>Deja Brady</strong> sent you a friend request</p>`) ||
    (index === 1 &&
      `<p><strong>Jayvon Hull</strong> mentioned you in <strong><a href='#'>Minimal UI</a></strong></p>`) ||
    (index === 2 &&
      `<p><strong>Lainey Davidson</strong> added file to <strong><a href='#'>File Manager</a></strong></p>`) ||
    (index === 3 &&
      `<p><strong>Angelique Morse</strong> added new tags to <strong><a href='#'>File Manager<a/></strong></p>`) ||
    (index === 4 &&
      `<p><strong>Giana Brandt</strong> request a payment of <strong>$200</strong></p>`) ||
    (index === 5 && `<p>Your order is placed waiting for shipping</p>`) ||
    (index === 6 && `<p>Delivery processing your order is being shipped</p>`) ||
    (index === 7 && `<p>You have new message 5 unread messages</p>`) ||
    (index === 8 && `<p>You have new mail`) ||
    '',
}));

// ----------------------------------------------------------------------

export const _mapContact = [
  {
    latlng: [33, 65],
    address: _mock.fullAddress(1),
    phoneNumber: _mock.phoneNumber(1),
  },
  {
    latlng: [-12.5, 18.5],
    address: _mock.fullAddress(2),
    phoneNumber: _mock.phoneNumber(2),
  },
];

// ----------------------------------------------------------------------

export const _socials = [
  {
    value: 'facebook',
    name: 'FaceBook',
    icon: 'eva:facebook-fill',
    color: '#637381',
    path: 'https://www.facebook.com/caitlyn.kerluke',
  },
  {
    value: 'instagram',
    name: 'Instagram',
    icon: 'ant-design:instagram-filled',
    color: '#637381',
    path: 'https://www.instagram.com/caitlyn.kerluke',
  },
  {
    value: 'linkedin',
    name: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#637381',
    path: 'https://www.linkedin.com/caitlyn.kerluke',
  },
  {
    value: 'twitter',
    name: 'Twitter',
    icon: 'eva:twitter-fill',
    color: '#637381',
    path: 'https://www.twitter.com/caitlyn.kerluke',
  },
];

// ----------------------------------------------------------------------

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: ['Standard', 'Standard Plus', 'Extended'][index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: [
    'JavaScript version',
    'TypeScript version',
    'Design Resources',
    'Commercial applications',
  ],
  icons: [
    '/assets/icons/platforms/ic_figma.svg',
    '/assets/icons/platforms/ic_js.svg',
    '/assets/icons/platforms/ic_ts.svg',
  ],
}));

// ----------------------------------------------------------------------

export const _pricingPlans = [
  {
    subscription: 'basic',
    price: 0,
    caption: 'Forever',
    lists: ['3 Prototypes', '3 Boards', 'Up To 5 Team Members'],
    labelAction: 'Current Plan',
  },
  {
    subscription: 'starter',
    price: 4.99,
    caption: 'Saving $24 a year',
    lists: [
      '3 Prototypes',
      '3 Boards',
      'Up To 5 Team Members',
      'Advanced Security',
      'Issue Escalation',
    ],
    labelAction: 'Choose Starter',
  },
  {
    subscription: 'premium',
    price: 9.99,
    caption: 'Saving $124 a year',
    lists: [
      '3 Prototypes',
      '3 Boards',
      'Up To 5 Team Members',
      'Advanced Security',
      'Issue Escalation',
      'Issue Development license',
      'Permissions & workflows',
    ],
    labelAction: 'Choose Premium',
  },
];

// ----------------------------------------------------------------------

export const _testimonials = [
  {
    name: 'Галимзянов Мунир Сабирзянович',
    postedDate: '12 августа',
    ratingNumber: _mock.number.rating(8),
    avatarUrl: _mock.image.avatar(1),
    content: ` Главный агроном ИП ГКФХ «Пономаренко А. И.» (Псковская обл., Печерский р-н, д. Летний Борок) отмечает, что машина СМВО-1 с вибростолом и элеватором загрузки проста в работе и не требует запасных частей. Её используют для доочистки семян льна и конопли`,
  },
  {
    name: 'Иван Терентьевич Быстров',
    postedDate: '15 ноябоя 2024',
    ratingNumber: _mock.number.rating(8),
    avatarUrl: _mock.image.avatar(2),
    content: `Уже два сезона, как отработала эта машина в нашем хозяйстве. И отрадно отметить, что мы не ошиблись в своём выборе при её покупке. В виду простоты конструкции машину мы монтировали своими силами. Очиститель работает без вопросов! Качество очистки зерна, пропускаемого через ОЗФ-50, меня, как агронома, вполне устраивает`,
  },
  {
    name: 'КФХ Мезенцев В. И',
    postedDate: '1 Ноября 2023',
    ratingNumber: _mock.number.rating(8),
    avatarUrl: _mock.image.avatar(3),
    content: `Говорит о высокой эффективности семяочистительной машины СМВО-10 Б. Она отлично очищает семена зерновых, выделяет щуплые и легковесные семена, удаляет овсюг на 100%. После первичной очистки хорошо доочищает подсолнух`,
  },
  {
    name: 'Виктор Иванович Толстолуцкий',
    postedDate: '10 октября 2024',
    ratingNumber: _mock.number.rating(8),
    avatarUrl: _mock.image.avatar(4),
    content: `Руководитель хозяйства Новооскольского района, Белгородской области. «У меня ежегодно во время уборки зерновых работало пять зерноочистительных машин, и всё время ток был завален зерном из-за их малой производительности. В результате чего мы несли большие затраты на доведение зерна до агротехнических требований. Со времени установки ОЗФ-50 на один из ЗАВов-20 прошло 4 года. В первый год положение с обработкой зерна в корне изменилось. За весь период уборки на току не находилось ни одной тонны зерна. Одна машина успевала перерабатывать зерно от 10 комбайнов марки ДОН. Более того, раньше отходы, получаемые от очистки зерна, работники брали на корм скоту, так как там было много зерна. С пуском этой машины отходы стали вывозить на свалку из-за отсутствия в них зерна. В ворохе оставались только рубашки и семена трав».`,
  },
  {
    name: 'ООО «Астарта» ',
    postedDate: '12 Августа 2022',
    ratingNumber: _mock.number.rating(8),
    avatarUrl: _mock.image.avatar(5),
    content: `отмечает хорошую работу самопередвижной машины СМВО-8 П для вторичной очистки. Машина отлично очищает семена, на семена отбираются только самые тяжёлые, полноценные зёрна, при этом на 100% очищает от овсюга. Зерновые отлично очищает после комбайна.`,
  },
];
