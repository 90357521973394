import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: {
    Token: 'JMzl9dW10xosQ8BHwi0xRgPdIzZX8Eee',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  menu: (id: number) => `/counterparty/menus/${id}/items`,
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  shops: 'counterparty/shops',
  auth: {
    me: 'counterparty',
    login: 'counterparty/login',
    register: 'auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    // list: (page: number) => `/counterparty/pages?page_type_id=${page}`,
    list: 'counterparty/pages',
    details: (slug: string) => `/counterparty/pages/${slug}`,
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/counterparty/products',
    details: '/counterparty/products',
    search: '/api/product/search',
    checkout: '/counterparty/contact_form',
  },
  category: {
    list: '/counterparty/categories',
  },
};
