export const POST_PUBLISH_OPTIONS = [
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

export const POST_SORT_OPTIONS = [
  { value: 'date_asc', label: 'Дата: Новые' },
  { value: 'date_desc', label: 'Дата: Старые' },
  { value: 'popularity_asc', label: 'Популярные' },
  { value: 'popularity_desc', label: 'Не популярные' },
];
