import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import axiosInstance, { fetcher, endpoints } from 'src/utils/axios';
// types
import { IProductItem } from 'src/types/product';

// ----------------------------------------------------------------------

const formateProducts = (products: any) => {
  const formatedProducts = products?.map((product: any) => {
    const formatedProduct: Partial<IProductItem> = {
      id: product.id,
      name: product.name,
      description: product.description,
      quantity: product.quantity,
      price: product.price,
      priceSale: product.old_price || 0,
      coverUrl: product.photo || product.photos?.[0]?.path,
      colors: [],
      available: product.quantity || 0,
      sizes: [],
      images: product.photos || [],
      specification: product.specification || '',
      totalReviews: product.views_count,
      newLabel: {
        enabled: false,
        content: '',
      },
      saleLabel: {
        enabled: false,
        content: '',
      },
    };

    return formatedProduct;
  });

  return formatedProducts;
};

interface IGetProducts {
  query: string;
  sort: string;
  page?: number;
  isGlobalSearch?: boolean;
}

export function useGetProducts(params: IGetProducts) {
  const {
    query,
    sort,
    page: originPage,
    isGlobalSearch,
  } = params;

  const requestParams: any = {
    page: originPage,
    order_by: sort,
  };

  if (query) {
    requestParams.query = query;
  }

  const URL = (isGlobalSearch && !query) ? null : [endpoints.product.list, { params: requestParams }];

  const { data: response, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const products = formateProducts(response?.products?.data);

  const page = response?.products?.current_page;
  const perPage = response?.products?.per_page;
  const total = response?.products?.total;

  const memoizedValue = useMemo(
    () => ({
      products: (products as IProductItem[]) || [],
      productsLoading: isLoading,
      productsError: error,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products?.length,
      pagination: {
        page,
        perPage,
        total,
      }
    }),
    [products, error, isLoading, isValidating, page, perPage, total]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetProduct(productId: string) {
  const URL = productId ? `${endpoints.product.details}/${productId}` : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const [product] = formateProducts(data?.product ? [data?.product] : []);

  const memoizedValue = useMemo(
    () => ({
      product: product as IProductItem,
      productLoading: isLoading,
      productError: error,
      productValidating: isValidating,
    }),
    [product, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useSearchProducts(query: string) {
  const URL = query ? [endpoints.product.list, { params: { query } }] : null;

  const {
    data: response,
    isLoading,
    error,
    isValidating,
  } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const products = formateProducts(response?.products?.data);

  const memoizedValue = useMemo(
    () => ({
      searchResults: (products as IProductItem[]) || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !products?.length,
    }),
    [products, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export const createProductCheckout = async (data: any) => {
  const response = await axiosInstance.post(endpoints.product.checkout, data);
  return response?.data;
};
