import { alpha, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useGetPage } from 'src/api/page';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
import { bgGradient } from 'src/theme/css';

export const WelcomeView = () => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const {
    pageItems,
  } = useGetPage('user-agreement');

  const theme = useTheme();
  const navigate = useNavigate();

  const handleModalState = () => {
    setIsModalOpened((prev) => !prev);
  };

  const acceptPolicy = () => {
    navigate(paths.dashboard.root);
  };

  useEffect(() => {
    if (!pageItems[0]) {
      return;
    }

    handleModalState();
  }, [pageItems])

  return (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      component="main"
      sx={{
        minHeight: '100vh',
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    >
      <Typography
        variant="h3"
        sx={{ maxWidth: 644, textAlign: 'center' }}
        fontSize={{
          xs: 16,
          sm: 32,
        }}
        fontWeight={700}
        lineHeight={{
          xs: '24px',
          sm: '48px',
        }}
      >
        Добро пожаловать в мир инноваций, где идеи превращаются в реальность.
      </Typography>

      <Box
        component="img"
        alt="auth"
        src="/assets/illustrations/welcome.png"
        sx={{ maxWidth: 720, width: '100%', aspectRatio: '21/9' }}
      />

      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{
          padding: '11px 0',
          width: '100%',
          maxWidth: 214,
          fontSize: 14,
          fontWeight: 700,
          lineHeight: '24px',
          gap: 1,
        }}
        endIcon={
          <CircularProgress
            size={24}
            style={{
              color: '#fff',
            }}
          />
        }
      >
        Загрузка...
      </Button>

      <ConfirmDialog
        open={isModalOpened}
        title={
          <Stack flexDirection="row" gap={1} alignItems="center">
            <Iconify icon="mdi:clipboard-outline" width={16} />

            <Typography fontWeight={700}>Соглашение</Typography>
          </Stack>
        }
        maxWidth="lg"
        content={pageItems?.[0]?.content}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{
              fontSize: 14,
              fontWeight: 700,
              lineHeight: '24px',
            }}
            onClick={acceptPolicy}
          >
            Принимаю
          </Button>
        }
        onClose={handleModalState}
      />
    </Stack>
  );
};
