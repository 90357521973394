import { Autocomplete, autocompleteClasses, Avatar, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useState } from 'react';
import { useGetProducts } from 'src/api/product';
import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';
import { useDebounceCallback } from 'src/hooks/use-debounce';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from 'src/routes/paths';
import { ISearchProps } from '../interfaces';

export const Search = (props: ISearchProps) => {
  const [search, setSearch] = useState<string>('');

  const navigate = useNavigate();

  const {
    products,
    productsLoading,
  } = useGetProducts({
    query: search,
    sort: '',
    isGlobalSearch: true,
  });

  const [debounce] = useDebounceCallback((currentValue: string) => {
    setSearch(currentValue);
  }, 700);

  const onChangeHandler = (value: string) => {
    debounce(value);
  };

  return (
    <Autocomplete
      sx={{ width: '100%', } }
      loading={productsLoading}
      autoHighlight
      popupIcon={null}
      options={products}
      onInputChange={(event, newValue) => {
        if (event.type === 'click') {
          const product = products.find((productItem) => productItem.name === newValue);
          if (!product) {
            return;
          }
          
          navigate(`${paths.dashboard.root}${paths.product.root}/${product?.id}`);
        }

        onChangeHandler(newValue)
      }}
      getOptionLabel={(option) => option.name}
      noOptionsText={<SearchNotFound query={products?.length ? '' : 'empty'} sx={{ bgcolor: 'unset' }} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      slotProps={{
        popper: {
          placement: 'bottom-start',
          sx: {
            minWidth: 320,
          },
        },
        paper: {
          sx: {
            [` .${autocompleteClasses.option}`]: {
              pl: 0.75,
            },
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Я ищу..."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {productsLoading ? <Iconify icon="svg-spinners:8-dots-rotate" sx={{ mr: -3 }} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(conf, product, { inputValue }) => {
        const matches = match(product.name, inputValue);
        const parts = parse(product.name, matches);

        return (
          <li {...conf} key={product.id}>
            <Link to={`${paths.product.root}/${product.id}`}>
              <Avatar
                key={product.id}
                alt={product.name}
                src={product.coverUrl}
                variant="rounded"
                sx={{
                  width: 48,
                  height: 48,
                  flexShrink: 0,
                  mr: 1.5,
                  borderRadius: 1,
                }}
              />
            </Link>

            <Stack direction='row' key={inputValue}>
              {parts.map((part, index) => (
                <Typography
                  key={index}
                  component="span"
                  color={part.highlight ? 'primary' : 'textPrimary'}
                  sx={{
                    typography: 'body2',
                    fontWeight: part.highlight ? 'fontWeightSemiBold' : 'fontWeightMedium',
                  }}
                >
                  {part.text}
                </Typography>
              ))}
            </Stack>
          </li>
        )
      }}
    />
  );
};
