import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
// components
import { useGetMenu } from 'src/api/nav';

// ----------------------------------------------------------------------
const getPath = (url: string) => {
  if (!url) {
    return '';
  }

  if (url === '/dashboard') {
    return url;
  }

  return `${paths.dashboard.root}${url}`
}

export function useNavData() {
  const {
    menuItems,
  } = useGetMenu();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        items: menuItems.map((menuItem) => {
          const children = menuItem.children.map((item) => ({
            title: item.name,
            path: `${paths.dashboard.root}${item.url}`,
          }));

          return {
            title: menuItem.name,
            path: getPath(menuItem.url),
            // icon: ICONS.dashboard,
            icon: menuItem.avatar,
            ...(children?.length && {
              children
            }),
          }
        }),
      },
    ],
    [menuItems]
  );

  return data;
}
