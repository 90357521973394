import { useMemo } from 'react';
import { fetcher } from 'src/utils/axios';
import useSWR from 'swr';

export function useGetPage(page: number | string) {
  let URL: any = '';

  if (typeof page === 'string') {
    URL = `counterparty/pages/${page}`
  } else {
    URL = ['counterparty/pages', {
      params: {
        page_type_id: page,
      },
    }];
  }

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      pageItems: ((data?.pages ? data.pages : [data?.page]) as any[]) || [],
      pageItemsLoading: isLoading,
      pageItemsError: error,
      pageItemsValidating: isValidating,
      pageItemsEmpty: !isLoading && !data?.pages?.length,
    }),
    [data?.pages, data?.page, error, isLoading, isValidating]
  );

  return memoizedValue;
}
